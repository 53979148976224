import React from 'react'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Helmet from 'react-helmet'
import { StaticQuery, graphql, withPrefix } from 'gatsby'

const detailsSEOQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        siteUrl
        title
        description
        author
      }
    }
  }
`

function SEO(props: any) {
  const { description, lang, keywords, title, featuredImagePath, slug } = props

  return (
    <StaticQuery
      query={detailsSEOQuery}
      render={(data) => {
        const ogImagePath =
          featuredImagePath || `${withPrefix('/')}img/og-image.png`
        const ogImage = `${data.site.siteMetadata.siteUrl}${ogImagePath}`
        const path = slug || '/'
        const pageUrl = `${data.site.siteMetadata.siteUrl}${path}`
        const metaDescription =
          description || data.site.siteMetadata.description

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
          >
            <meta name="theme-color" content="#fff" />

            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />

            <meta property="description" content={metaDescription} />

            <meta property="keywords" content={keywords.join(', ')} />

            <meta property="og:type" content="website.article" />

            <meta property="og:title" content={title} />

            <meta property="og:url" content={pageUrl} />

            <meta property="og:image" content={ogImage} />

            <meta property="og:description" content={metaDescription} />

            <meta property="og:locale" content="ja_JP" />

            <meta property="twitter:card" content="summary_large_image" />

            <meta property="twitter:title" content={title} />

            <meta property="twitter:description" content="gonjitti" />

            <meta property="twitter:image" content={ogImage} />

            <meta property="twitter:site" content="@gonjitti" />

            <meta property="twitter:creator" content="@gonjitti" />

            <script
              type="text/javascript"
              async
              src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
            />
            {/* <script type="text/javascript" async src='https://cse.google.com/cse.js?cx=partner-pub-1547867600878186:1705074540'></script> */}
          </Helmet>
        )
      }}
    />
  )
}

export default SEO
